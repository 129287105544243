import { css } from "@emotion/react";
import { color } from "../../constants";
import Icon from "./Icon";

const style = Object.freeze({
  container: css`
    display: block;
    border-radius: 32px;
    border: 0;
    padding: 8px 24px;
    margin: 16px auto;
    width: 100%;
    font-weight: bold;
    font-size: 0.9rem;
    cursor: pointer;
    text-align: center;
  `,
  icon: css`
    margin-right: 32px;
  `,
  text: css`
    text-align: left;
    width: 100%;
  `,
  primary: css`
    background: ${color.primary};
    transition: all 0.5s linear;

    &:hover {
      box-shadow: 1px 1px 6px 1px #31270124, 0px 1px 1px 0px #31270138;
      transition: all 0.5s linear;
    }
    &:active {
      box-shadow: unset;
      transition: all 0.3s linear;
    }
  `,
  default: css``,
  secondary: css`
    background: ${color.fontColor};
    color: ${color.white};
    &:hover {
      box-shadow: 0px 2px 4px 3px #46464610, 0px 1px 4px 2px #77777780;
      transition: all 0.5s linear;
    }
    &:active {
      box-shadow: unset;
      transition: all 0.3s linear;
    }
  `,
  info: css`
    background: ${color.info};
    font-weight: normal;
  `,
  outline: css`
    border: 2px solid ${color.fontColor};
    font-weight: bold;
    &:hover {
      box-shadow: 0px 2px 4px 3px #46464610, 0px 1px 4px 2px #77777780;
      transition: all 0.5s linear;
    }
    &:active {
      box-shadow: unset;
      transition: all 0.3s linear;
    }
  `,
  contentWrapper: css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 0;
    }
  `,
  disabled: css`
    opacity: 0.5;
  `,
});

interface Props {
  type?: "primary" | "secondary" | "info" | "outline";
  icon?: "pin" | "download" | "cut";
  label?: string;
  disabled?: boolean;
  onClick: () => void | Promise<void>;
}

const Button: React.FC<Props> = ({ type, icon, label, disabled, onClick }) => {
  const buttonStyle = (style as any)[type || ""] || style.default;

  const _onClick = () => {
    if (disabled) {
      return;
    }

    onClick();
  };

  return (
    <div
      css={[style.container, buttonStyle, disabled && style.disabled]}
      onClick={_onClick}
    >
      {icon ? (
        <div css={style.contentWrapper}>
          <Icon css={style.icon} icon={icon} size="18px" />
          <p css={style.text}>{label}</p>
        </div>
      ) : (
        label
      )}
    </div>
  );
};

export default Button;
