import { css } from "@emotion/react";
import { Link } from "gatsby";
import Share from "organisms/Share";

const style = Object.freeze({
  container: css`
    font-size: 0.8rem;
    padding 8px;
    padding-top: 16px;
    padding-left: 32px;

    li {
      margin-bottom: 8px;
      margin-right: 8px;
    }
  `,
  nav: css`
    margin-bottom: 32px;
  `,
  sns: css``,
});

const Sidebar: React.FC = () => {
  return (
    <div css={style.container}>
      <div css={style.nav}>
        <ul>
          <li>
            <Link to="/">トップ</Link>
          </li>
          <li>
            <Link to="/docs">ドキュメント</Link>
          </li>
          <li>
            <a href="https://forms.gle/hgu5ZVNZcgounTV66">お問い合わせ</a>
          </li>
        </ul>
      </div>
      <div css={style.sns}>
        <Share />
      </div>
    </div>
  );
};

export default Sidebar;
