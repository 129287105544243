import { css } from "@emotion/react";
import Sidebar from "templates/Sidebar";
import Title from "assets/images/logo-with-text.svg";
import Icon from "atoms/Icon";
import { Provider } from "context/index";
import Modal from "templates/Modal";
import Head from "templates/Head";

const style = Object.freeze({
  header: css`
    background: white;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
    padding: 8px 16px;
  `,
  content: css`
    width: 100%;
  `,
  container: css`
    display: flex;
  `,
  sidebar: css`
    width: 300px;
    min-width: 300px;
    border-right: 1px solid #f0f0f0;
  `,
  logo: css`
    display: flex;
    align-items: center;

    img {
      height: 32px;
    }

    p {
      margin-left: 16px;
      font-weight: bold;
    }
    span {
      margin-left: 8px;
    }
  `,
  help: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    right: 32px;
    bottom: 32px;

    &:hover .icon-down {
      animation: 0.3s linear 0s infinite alternate bounding;
    }
    @keyframes bounding {
      0% {
        top: 0;
      }
      100% {
        top: 4px;
      }
    }
  `,
  arrowdown: css`
    position: relative;
  `,
});

interface Props {
  transition?: string;
  navigate?: (key: string) => void;
  sidebar?: JSX.Element;
  children?: JSX.Element[] | JSX.Element;
}

const Layout: React.FC<Props> = ({
  children,
  sidebar,
  transition,
  navigate,
}) => {
  return (
    <div>
      <Modal />
      <Head />
      <header css={style.header}>
        <div css={style.logo}>
          <img src={Title} alt="ロゴ" />
        </div>
      </header>
      <main css={style.container}>
        <Provider>
          <div css={style.sidebar}>
            {sidebar || <Sidebar navigate={navigate} />}
          </div>
          <div css={style.content}>{children}</div>
        </Provider>
      </main>
      {transition === "index" && (
        <div css={style.help}>
          <Icon
            icon="help"
            size="48px"
            onClick={() => {
              navigate && navigate("help");
            }}
          />
          <div className="icon-down">
            <Icon css={style.arrowdown} icon="down" size="18px" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Layout;
