import React, { createContext, useCallback, useState } from "react";
import Audio from "models/audio";
import { isProduction } from 'constants/index'

interface AudioContext {
  state: { list: Audio[]; current: Audio | undefined };
  update: { [key: string]: Function };
}

const context = createContext<AudioContext>({
  state: {
    list: [],
    current: undefined,
  },
  update: {},
});

export const useContext = () => React.useContext(context)

export const Provider: React.FC = ({ children }) => {
  const [list, setList] = useState<Audio[]>([]);
  const [current, setCurrent] = useState<Audio>();

  const updateCurrent = useCallback((item: Audio) => {
    if (!current || !list[current.index!]) {
      return
    }
    const _list = [...list]
    _list[current.index!] = item
    setList(_list)
    setCurrent(item)
  }, [current, list])

  if (!isProduction) {
    console.log('context state =============')
    console.log('list', list)
    console.log('current', current)
  }

  return (
    <context.Provider
      value={{
        state: {
          list,
          current,
        },
        update: {
          setCurrent,
          updateCurrent,
          setList
        },
      }}
    >
      {children}
    </context.Provider>
  );
};
