import React from "react";
import { css } from "@emotion/react";
import { color } from "../../constants";

const style = Object.freeze({
  container: css`
    height: 200px;
    min-height: 200px;
    border-bottom: solid 1px ${color["gray-0"]};
    overflow: scroll;
  `,
  header: css`
    padding: 8px;
    padding-left: 32px;
    border-bottom: solid 1px ${color["gray-0"]};
  `,
  content: css`
    padding: 8px;
    font-size: 0.8rem;

    h3 {
      padding-left: 24px;
      font-size: 0.6rem;
    }
    p {
      padding-left: 24px;
      margin: 4px 0;
      margin-bottom: 8px;
    }

    li {
      border-bottom: 1px solid #cccccc20;
    }
  `,
});

interface Props {}

const list = [{ date: "2021/02/01", content: "ベータ版をリリースしました！" }];

const News: React.FC<Props> = ({}) => {
  return (
    <div css={style.container}>
      <div css={style.header}>
        <h2>お知らせ</h2>
      </div>
      <div css={style.content}>
        <ul>
          {list.map((item: any) => {
            return (
              <li key={item.content}>
                <h3>{item.date}</h3>
                <div>
                  <p>{item.content}</p>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default News;
