import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  PocketShareButton,
  EmailIcon,
  TwitterIcon,
  PocketIcon,
  FacebookIcon,
} from "react-share";
import { meta } from "constants/index";
import { css } from "@emotion/react";

const style = Object.freeze({
  container: css`
    display: flex;
  `,
});

const Share: React.FC = () => {
  return (
    <ul css={style.container}>
      <li>
        <EmailShareButton
          url={meta.url}
          subject={meta.title}
          body={meta.description}
        >
          <EmailIcon size="24" round />
        </EmailShareButton>
      </li>
      <li>
        <FacebookShareButton url={meta.url} quote={meta.description}>
          <FacebookIcon size="24" round />
        </FacebookShareButton>
      </li>
      <li>
        <TwitterShareButton
          url={meta.url}
          title={meta.title}
          related={[meta.twitter.creator]}
          hashtags={["EggCutter", "英語学習", "English"]}
        >
          <TwitterIcon size="24" round />
        </TwitterShareButton>
      </li>
      <li>
        <PocketShareButton url={meta.url} title={meta.title}>
          <PocketIcon size="24" round />
        </PocketShareButton>
      </li>
    </ul>
  );
};

export default Share;
