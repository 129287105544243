import produce, { immerable } from "immer";

export interface Point {
  name: string;
  head: number;
  tail: number;
  link?: string;
  blob?: Blob;
}

interface Params {
  index?: number;
  file: File;
  points: Point[];
}

export default class Audio {
  [immerable] = true;

  public index: number | undefined;
  public file: File;
  public archiveUrl: string | Blob | undefined;
  public points: Point[] = [];

  constructor(data: Params) {
    Object.assign(this, data);
  }

  get name() {
    return this.file.name;
  }

  get path() {
    return (window.URL || window.webkitURL).createObjectURL(this.file);
  }

  get archiveName() {
    return this.name.slice(0, this.name.lastIndexOf('.')) + '.zip'
  }

  setPoints(list: Point[]) {
    return produce(this, (draft) => {
      draft.points = list;
    });
  }

  setArchiveUrl(url?: string | Blob) {
    return produce(this, (draft) => {
      draft.archiveUrl = url;
    });
  }
}
