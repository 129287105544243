import { useRef } from "react";
import { css } from "@emotion/react";
import Icon from "atoms/Icon";
import Button from "atoms/Button";
import Audio from "models/audio";
import { useContext } from "context/index";
import { color, limit } from "constants/index";

const style = Object.freeze({
  header: css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 4px;
    border: 1px solid ${color["gray-0"]};
  `,
  icon: css`
    margin-right: 8px;
  `,
  container: css``,
  content: css`
    height: 340px;
    overflow: scroll;
    border: 1px solid ${color["gray-0"]};
  `,
  empty: css`
    padding: 32px;
    padding-top: 64px;

    p {
      font-size: 0.8rem;
      text-align: center;
      margin-bottom: 8px;
    }

    aside {
      font-size: 0.6rem;
      text-align: center;
      margin-bottom: 32px;
    }
  `,
  item: css`
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    padding-left: 64px;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;

    &:hover {
      background: ${color.info};
    }
  `,
  actions: css``,
  removeItem: css`
    opacity: 0;
    transtion: all 0.3s ease-out;

    &:hover {
      opacity: 1;
    }
  `,
  active: css`
    background: ${color.info};
  `,
});

const FileList: React.FC = () => {
  const {
    state: { current, list },
    update: { setList, setCurrent },
  } = useContext();
  const fileRef = useRef<any>();

  const onUpload = (e: any) => {
    const len = e.target.files.length + list.length
    if (len > limit.maxFileList) {
      alert(`ファイルは${limit.maxFileList}個以上選択できません。`);
      return;
    }

    const _list = [...e.target.files].map((item, index) => {
      return new Audio({
        index,
        file: item,
        points: [],
      });
    });
    setList(_list);
    setCurrent(_list[0]);
  };

  const onSelectFile = () => fileRef.current?.click();

  const onRemoveAll = () => {
    if (!confirm("選択したファイルをリストから削除しますか？")) {
      return;
    }
    setCurrent(undefined);
    setList([]);
  };

  return (
    <div css={style.container}>
      <div css={style.header}>
        <Icon css={style.icon} icon="upload" onClick={onSelectFile} />
        <Icon
          css={style.icon}
          icon="cancel"
          size="14px"
          onClick={onRemoveAll}
        />
      </div>
      <div css={style.content}>
        <input
          type="file"
          style={{ display: "none" }}
          multiple
          accept=".mp3"
          ref={fileRef}
          onChange={onUpload}
        />
        {list.length > 0 ? (
          <ul>
            {list.map((audio: Audio, index: number) => {
              const active = current?.index === audio.index;
              return (
                <li
                  key={audio.name}
                  css={[style.item, active && style.active]}
                  onClick={() => {
                    setCurrent(audio);
                  }}
                >
                  <div>{audio.name}</div>
                  {!active && (
                    <div css={style.removeItem}>
                      <Icon
                        icon="cancel"
                        size="14px"
                        onClick={(e) => {
                          e.stopPropagation()
                          if (
                            !confirm(`${audio.name} をリストから削除しますか？`)
                          ) {
                            return;
                          }
                          const _list = [...list];
                          _list.splice(index, 1);

                          setList(_list);
                        }}
                      />
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        ) : (
          <div css={style.empty}>
            <p>分割するファイルを選択してください。</p>
            <aside>一度に選択できるファイルは {limit.maxFileList} つまで</aside>
            <Button
              type="secondary"
              label="アップロード"
              onClick={onSelectFile}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FileList;
