import { useMemo } from "react";
import {
  AiOutlineArrowDown,
  AiOutlineArrowUp,
  AiOutlineArrowRight,
  AiOutlineArrowLeft,
  AiOutlinePause,
  AiOutlineMore,
  AiOutlineUpload,
  AiOutlineDownload,
  AiOutlineClose,
  AiOutlineScissor,
  AiOutlineTwitter,
  AiOutlineUndo,
  AiOutlineRedo,
  AiOutlineDown,
  AiTwotonePushpin,
  AiFillCaretRight,
  AiFillQuestionCircle,
  AiFillFacebook,
  AiFillInstagram,
  AiFillSound,
  AiFillEdit,
  AiFillDelete,
  AiFillCheckCircle,
  AiOutlineUp,
  AiOutlineRight,
  AiOutlineLeft,
} from "react-icons/ai";
import { css } from "@emotion/react";
import { color as gColor } from "../../constants";

const icons = {
  arrowdown: AiOutlineArrowDown,
  arrowup: AiOutlineArrowUp,
  arrowleft: AiOutlineArrowLeft,
  arrowright: AiOutlineArrowRight,
  cancel: AiOutlineClose,
  down: AiOutlineDown,
  up: AiOutlineUp,
  right: AiOutlineRight,
  left: AiOutlineLeft,
  play: AiFillCaretRight,
  pause: AiOutlinePause,
  menu: AiOutlineMore,
  upload: AiOutlineUpload,
  undo: AiOutlineUndo,
  redo: AiOutlineRedo,
  download: AiOutlineDownload,
  pin: AiTwotonePushpin,
  cut: AiOutlineScissor,
  help: AiFillQuestionCircle,
  twitter: AiOutlineTwitter,
  facebook: AiFillFacebook,
  instagram: AiFillInstagram,
  sound: AiFillSound,
  edit: AiFillEdit,
  delete: AiFillDelete,
  check: AiFillCheckCircle,
};

const style = Object.freeze({
  disabled: css`
    opacity: 0.4;
  `,
  default: css`
    cursor: pointer;
  `,
});

interface Props {
  icon: keyof typeof icons;
  size?: string;
  color?: string;
  css?: any;
  disabled?: boolean;
  onClick?: (e: any) => void;
}

const Icon: React.FC<Props> = ({
  icon,
  size = "18px",
  color,
  onClick = () => {},
  disabled,
  ...rest
}) => {
  const Component = useMemo(() => icons[icon], [icon]);
  const _onClick = (e: any) => {
    if (disabled) {
      return;
    }
    onClick && onClick(e);
  };
  if (disabled) {
    return (
      <Component
        size={size}
        color={color || gColor.fontColor}
        css={style.disabled}
        {...rest}
      />
    );
  }

  return (
    <Component
      size={size}
      color={color || gColor.fontColor}
      css={style.default}
      onClick={_onClick}
      {...rest}
    />
  );
};

export default Icon;
