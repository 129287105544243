import { Helmet } from "react-helmet";
import { meta } from "constants/index";

const { title, url, lan, description, thumbnail, twitter } = meta;

const Head: React.FC = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Egg Cutter | オンライン mp3 分割サービス</title>
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={title} />
      <meta property="og:image" content={thumbnail} />
      <meta property="og:locale" content={lan} />
      <meta name="twitter:card" content={twitter.card} />
      <meta name="twitter:domain" content={twitter.domain} />
      <meta name="twitter:creator" content={twitter.creator} />
      <meta name="twitter:site" content={twitter.site} />
      <script
        data-ad-client="ca-pub-6597797627874207"
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      ></script>
    </Helmet>
  );
};

export default Head;
