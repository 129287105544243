export const color = {
  gray: "#f0f0f050",
  "gray-0": "#f0f0f0",
  "gray-1": "#eeeeee",
  "gray-2": "#cccccc",
  "gray-3": "#aaaaaa",
  "gray-4": "#888888",
  "gray-5": "#666666",
  "gray-6": "#444444",
  "gray-7": "#222222",
  "gray-8": "#000000",
  white: "#fefefe",
  fontColor: "#202020",
  info: "#FFEBA5",
  primary: "#FAC404",
  success: "#00b75b",
  link: "#00A0B6",
  danger: "red",
};
export const meta = Object.freeze({
  title: `Egg Cutter | 音声分割アプリ`,
  description: `
Egg Cutterは音声ファイルを分割することのできるWebサービスです。
音声を流しながらご自身の好きなタイミングで分割位置を決めてファイルを分割することができます。
好きなファイルを選択して自由にファイルを分割してみてください。
`,
  url: `https://egg-cutter.net`,
  lan: "ja",
  twitter: {
    card: "summary_large_image",
    domain: "",
    creator: "@version1_2017",
    site: "@egg_cutter",
  },
  thumbnail:
    "https://s3-ap-northeast-1.amazonaws.com/statics.egg-cutter.net/assets/imagas/thumbnail.png",
});

export const limit = Object.freeze({
  maxPinList: 50,
  maxFileList: 10,
});

export const isProduction = process.env.NODE_ENV === "production";
