import { css } from "@emotion/react";
import { color } from "constants/index";
import FileList from "organisms/FileList";
import News from "organisms/News";
import Footer from "templates/sidebar/Footer";

const style = Object.freeze({
  content: css``,
  container: css``,
  fileList: css``,
  ads: css`
    height: 100px;
    min-height: 100px;
    border-bottom: solid 1px ${color["gray-0"]};
  `,
});

interface Props {
  navigate: (key: string) => void;
}

const Sidebar: React.FC<Props> = ({ navigate }) => {
  return (
    <div css={style.container}>
      <div css={style.content}>
        <FileList />
        <div css={style.ads}></div>
        <News />
      </div>
      <Footer onClickAbout={() => navigate("help")} />
    </div>
  );
};

export default Sidebar;
